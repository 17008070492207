<template>
  <v-app class>
    <div>
      <v-alert v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
        {{alertMessage}}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-10 ml-auto mr-auto">
        <v-container>
          <div class="mb-4 mt-8 d-flex">
            <div class="col-md-11">
              <h1>Personnel Deductions</h1>
            </div>
            <div class="col-md-1">
              <Button :btnType="'Submit'" :label="'+'" @onClick="addNew" class="mr-2" />
            </div>
          </div>
          <v-text-field v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details></v-text-field>
          <v-data-table :loading="isLoading"
            loading-text="Fetching records... Please wait"
            :headers="headers"
            :items="deductionList"
            :search="search">
            <template v-slot:item.id="{ item }">
              {{ getEmployeeName(item.employee) }}
            </template>
            <template v-slot:item.effectiveDate="{ item }">
              {{ item.effectiveDate | formatDate }}
            </template>
            <template v-slot:item.dedecutionAmount="{ item }">
              {{ item.dedecutionAmount | formatMoney }}
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex">
                <Button :btnType="'Submit'" :label="'Edit'" @onClick="edit(item)" class="mr-2" />
                <Button :btnType="'Cancel'" :label="'Delete'" @onClick="openCancel(item)" class="mr-2" />
              </div>
            </template>
          </v-data-table>

          <!-- confirm delete modal  -->
          <Dialog ref="deleteModal" :title="'Are you sure you want to delete this deduction'" :width="420">
            <template v-slot:footer>
              <Button :btnType="'Submit'" :label="'Yes'" @onClick="cancelRecord" class="mr-2" />
              <Button :btnType="'Cancel'" :label="'No'" @onClick="cancel('deleteModal')" />
            </template>
          </Dialog>
        </v-container>
      </div>
    </div>
  </v-app>
</template>
<script>
// import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'
import { employeeDeductionService } from '@/services'
// import moment from 'moment'
// import { UPDATE_JOBTITLE, FETCH_EMPLOYEES, DELETE_JOBTITLE } from '@/store/action-type'
export default {
  components: {
    Button,
    Dialog
  },
  data () {
    return {
      search: '',
      isLoading: false,
      formData: {
        name: '',
        id: ''
      },
      valid: false,
      isSavingUser: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      medicalRecords: [],
      deductionList: [],
      headers: [
        {
          text: 'Personnel',
          value: 'id'
        },
        {
          text: 'Deduction Type',
          value: 'salaryComponent.description'
        },
        {
          text: 'Amount',
          value: 'dedecutionAmount'
        },
        {
          text: 'Effective Date',
          value: 'effectiveDate'
        },
        {
          text: 'Year',
          value: 'deductionYear'
        },
        {
          text: 'Department',
          value: 'employee.department.name'
        },
        {
          text: 'Location',
          value: 'employee.location.name'
        },
        {
          text: 'Actions',
          value: 'actions'
        }
      ]
    }
  },
  computed: {
    // employees () {
    //   return this.$store.getters.employees
    // }
  },
  methods: {
    addNew () {
      this.$router.push('/personnel-deduction/add')
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    edit (item) {

    },
    openCancel (item) {
      this.$refs.deleteModal.toggleModal()
      this.formData.id = item.id
    },
    cancelRecord () {
      // const approvals = []
      const that = this
      // approvals.push({
      //   id: this.formData.id,
      //   status: 7,
      //   comments: ''
      // })

      employeeDeductionService.deleteEmployeeDeduction(this.formData.id).then(() => {
        this.showAlertMessage('Deduction successfully deleted', 'success')
        this.getDeductions()
        that.$refs.deleteModal.toggleModal()
      }).catch(() => {
        this.showAlertMessage('Unable to delete deduction', 'error')
      })
    },
    cancel (modal) {
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.toggleModal()
      }
    },
    formatStatus (status) {
      switch (status) {
        case '0':
          return 'Pending'
        case '1':
          return 'Approved'
        case '2':
          return 'Rejected'
        case '7':
          return 'Cancelled'
        default:
          return ''
      }
    },
    getDeductions () {
      this.isLoading = true
      employeeDeductionService.getEmployeeDeductions().then(result => {
        this.deductionList = result.data.items
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },
    getEmployeeName (employee) {
      return `${employee.lastName}, ${employee.firstName} ${employee.middleName}`
    }
  },
  mounted () {
    this.getDeductions()
  }
}
</script>
<style scoped>
  .search-btn {
    background-color: #e6e3e3;
    height: 40px;
    border-radius: 3px !important;
  }

  .row-pointer > > > tbody tr :hover {
    cursor: pointer;
  }

  .alert {
    position: fixed;
    z-index: 9999;
    top: 5px;
    min-width: 50%;
  }

  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
</style>
